import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'ymizushi | 10gen', // e.g: 'Name | Developer'
  lang: 'ja', // e.g: en, es, fr, jp
  description: 'ymizushi / 10gen の紹介', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Yuta Mizushima',
  name: 'ymizushi',
  subtitle: 'Full-stack Developer',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '高校・大学時代は電子工作をすることが多く、ラジオの制作や、PIC/AVRマイコンでロボットや電光掲示板などを作って遊んでいました。PICはアセンブラで、AVRはC言語で開発していました。大学の卒業研究は GPGPUを使ったステレオビジョン。',
  paragraphTwo: 'キャリアとしてはサービスの企画から、フロント、インフラまで一貫して関わることが多かったです',
  paragraphThree: '自分が作ったサービスで人々の困っていることを解決していきたいです',
  resume: 'https://www.wantedly.com/id/ymizushi', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: '', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '開発依頼, 案件紹介などあればご連絡下さい',
  btn: 'メールで連絡する',
  email: 'mizushi@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'http://github.com/ymizushi',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/yutamizushima1985',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
